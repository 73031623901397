<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
  >
    <g clip-path="url(#clip0_39670_4028)">
      <path d="M5 8H1V19H5V8Z" stroke-width="1.5" stroke-miterlimit="10" />
      <path
        d="M3 5C4.10457 5 5 4.10457 5 3C5 1.89543 4.10457 1 3 1C1.89543 1 1 1.89543 1 3C1 4.10457 1.89543 5 3 5Z"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M13.5 8C12.98 8 12.48 8.08 12 8.21V8H8V19H12V13.5C12 12.67 12.67 12 13.5 12C14.33 12 15 12.67 15 13.5V19H19V13.5C19 10.46 16.54 8 13.5 8Z"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_39670_4028">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
